/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Grid, MenuItem, TablePagination, TextField } from '@material-ui/core';
import AlertCard from 'components/AlertCard';
import Scaffold from 'components/Scaffold';
import { MyTable } from 'components/Table';
import { TableColumnConfig } from 'components/Table/types';
import { selectMenuProps } from 'constants/selectMenu';
import React, { useMemo } from 'react';
import { Payment } from 'types/payment';
import { formatBRL, ROWS_PER_PAGE } from 'utils';
import { TABLE_HEADERS } from 'utils/tableHeaders';
import { ButtonDownloadIncomeTaxReturn } from './Components/ButtonDownloadIncomeTaxReturn';
import { useIncomeTaxReturnController } from './controller';

const IncomeTaxReturn: React.FC = () => {
  const {
    closeAlert,
    alertMessage,
    alertType,
    isShowAlert,
    handleSetValuePage,
    handleChangeReferenceYear,
    classes,
    total,
    page,
    rows,
    referenceYear,
    onChangeRowsPerPage,
    isLoading,
    payments,
    yearSelectOptions,
  } = useIncomeTaxReturnController();

  const tableConfig: TableColumnConfig<Payment>[] = useMemo(
    () => [
      {
        headerName: TABLE_HEADERS.YEAR,
        getter: 'referenceYear',
        onFormat: undefined,
        defaultValue: '-',
      },
      {
        headerName: TABLE_HEADERS.REGISTRATION,
        getter: 'registration.number',
        onFormat: undefined,
        defaultValue: '-',
      },
      {
        headerName: TABLE_HEADERS.DEPENDENT,
        getter: 'registration.dependent.name',
        onFormat: undefined,
        defaultValue: '-',
      },
      {
        headerName: TABLE_HEADERS.SERVICE,
        getter: 'service.name',
        defaultValue: 'Ano letivo',
      },
      {
        headerName: TABLE_HEADERS.TOTAL_VALUE,
        getter: 'value',
        onFormat: (value: any) => formatBRL(value),
        defaultValue: '-',
      },
      {
        headerName: TABLE_HEADERS.FILE,
        getter: 'id',
        onFormat: undefined,
        defaultValue: '-',
        customRender: (formattedValue: any, payment: Payment) => {
          return (
            <ButtonDownloadIncomeTaxReturn
              payment={payment}
              referenceYear={referenceYear}
              financialGuardianId={payment.financialGuardian.id}
            />
          );
        },
      },
    ],
    [referenceYear],
  );

  return (
    <>
      <AlertCard
        message={alertMessage}
        open={isShowAlert}
        close={closeAlert}
        severity={alertType}
      />
      <Scaffold
        rawTitle="Declaração do Imposto de Renda"
        rawSubtitle="Faça o download para a declaração do Imposto de Renda"
      >
        <Grid item>
          <TextField
            label="Ano"
            variant="outlined"
            select
            SelectProps={selectMenuProps}
            value={referenceYear}
            onChange={handleChangeReferenceYear}
            className={classes.filterField}
          >
            {yearSelectOptions.map(year => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {/* {TODO: criar componente tabela + paginação} */}
        <MyTable<Payment>
          data={payments}
          tableConfig={tableConfig}
          isLoading={isLoading}
          emptyTableMessage="Não há dados para serem exibidos."
        />
        <TablePagination
          component="div"
          count={total}
          page={page}
          labelRowsPerPage="Itens por página"
          onPageChange={(_event: unknown, newPage: number) =>
            handleSetValuePage(newPage)
          }
          rowsPerPage={rows}
          onChangeRowsPerPage={onChangeRowsPerPage}
          rowsPerPageOptions={ROWS_PER_PAGE}
        />
      </Scaffold>
    </>
  );
};

export default IncomeTaxReturn;
