import { ServiceClass } from 'pages/Client/MarketplaceServicesDetail/types';
import { Bill } from './bill';
import { Dependent } from './dependent';
import {
  PAYMENT_METHODS,
  PAYMENT_SOURCE,
  PAYMENT_STATUS,
  SCHOOL_GRADES,
} from './enums';
import { FinancialGuardian } from './financialGuardian';
import { Registration } from './registration';
import { Service } from './service';
import { Subscription } from './subscription';
import { UploadedDocument } from './uploadedDocument';
import { CreatedByAdmin } from './user';

export interface Payment {
  id: number;
  value: number;
  status: PAYMENT_STATUS;
  canExportIR: boolean;
  referenceYear: number;
  referenceGrade: SCHOOL_GRADES;
  subscriptions: Subscription[];
  /**
   * @deprecated use subscriptionStartDate
   */
  startDate: string;
  subscriptionStartDate: string;
  bills: Bill[];
  registration: Registration;
  contractInfo?: Contract;
  financialGuardian: FinancialGuardian;
  paymentMethodCode: PAYMENT_METHODS;
  canceledBy: CreatedByAdmin;
  discountedValue: number;
  registrationId: number;
  paymentStartDate: string;
  referencePrice: number;
  serviceId: number;
  serviceClassId: number;
  service?: Service;
  paymentFailReason?: string;
  dependentId?: number;
  dependent?: Dependent;
  source: PAYMENT_SOURCE;
  serviceClass?: ServiceClass;
  serviceClassEnrollmentId: number;
  createdAt: Date;
  canceledAt?: Date;
  originalValue?: number;
  paymentInvoiceServiceCode?: string;
  financialGuardianId?: number;
  serviceStartDate?: Date;
  serviceEndDate?: Date;
  startPaymentDate?: string;
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'Ativa',
  BILLED = 'Faturada',
  CANCELED = 'Cancelada',
  DEFAULTING = 'Inadimplente',
  FUTURE = 'Agendada',
  CLOSED = 'Fechada',
  FAILED = 'Falhou',
  DRAFT = 'Rascunho',
  EXPIRED_PIX = 'Pix vencido',
}

export interface Contract {
  id: number;
  ip: string;
  agent: string;
  signDate: string;
  assigner?: string;
  payment: Payment;
  document: UploadedDocument;
}
