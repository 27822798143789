import { CreateQueryParams } from '@nestjsx/crud-request';
import { SubscriptionsService } from 'pages/Client/MarketplacePaymentCheckout/types';
import { NewSubscription } from 'pages/Client/PaymentMethod/types';
import {
  api,
  getAxiosResponseMessageErrorOrDefault,
  getOneWithFilter,
  getWithFilter,
} from 'services/apiService';
import { Payment } from 'types/payment';
import { NestCrudPaginatedResponse, NestCrudSingleResponse } from './types';

export default class PaymentService {
  static async payments() {
    try {
      return await api.get('payments');
    } catch (error: any) {
      return error.response;
    }
  }

  // FIXME: remover a necessidade de explícitamente passar o filter
  // { field: 'financialGuardian', select: ['id'] }
  // p/ query não quebrar no back (CrudAuth)
  static async payment<T = Payment>(
    id: string,
    query?: CreateQueryParams,
  ): Promise<NestCrudSingleResponse<T>> {
    try {
      return await getOneWithFilter<T>('payments', id, query);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async queryPlans(query?: CreateQueryParams) {
    try {
      return await getWithFilter('/payments', query);
    } catch (err: any) {
      return err.response;
    }
  }

  static async subscription(id: number) {
    try {
      return await api.get(`subscriptions/${id}`);
    } catch (error: any) {
      return error.response;
    }
  }

  static async filterPayments<T = Payment>(
    query?: CreateQueryParams,
  ): Promise<NestCrudPaginatedResponse<T>> {
    try {
      return await getWithFilter<T>('/payments', query);
    } catch (err: any) {
      return err.response;
    }
  }

  static async getFilteredCsv(query?: CreateQueryParams) {
    try {
      return await getWithFilter('/payments/export-csv', query, {
        responseType: 'blob',
      });
    } catch (err: any) {
      return err.response;
    }
  }

  static async createDraftRegistrationPayment({
    registrationId,
    installments,
    paymentMethodCode,
    subscriptions,
  }: {
    registrationId: number;
    installments: number;
    paymentMethodCode: string;
    subscriptions: NewSubscription[];
  }) {
    try {
      return await api.post<Payment>('payments', {
        registrationId,
        installments,
        paymentMethodCode,
        subscriptions,
      });
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async getBills(query?: CreateQueryParams) {
    try {
      return await getWithFilter('/bills', query);
    } catch (err: any) {
      return err.response;
    }
  }

  static async getNextBankSlipInstallment(billId: Number) {
    try {
      return await api.post(
        `bills/${billId}/advance-installment-of-subscription`,
      );
    } catch (err: any) {
      return err.response;
    }
  }

  static async getMonthAndYearSum(year: number) {
    try {
      return await api.get(`/bills/sum-of-month-and-year-report?year=${year}`);
    } catch (err: any) {
      return err.response;
    }
  }

  static async getYearSum() {
    try {
      return await api.get('/bills/sum-of-year-report');
    } catch (err: any) {
      return err.response;
    }
  }

  static async cancelBill(id: number, refund: boolean) {
    try {
      return await api.post(`/bills/${id}/cancel`, { refund });
    } catch (err: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(err));
    }
  }

  static async cancelPayment(paymentId: number, refund: boolean) {
    try {
      return await api.post(`/payments/${paymentId}/cancel`, { refund });
    } catch (err: any) {
      return err.response;
    }
  }

  static async syncPaymentWithVindi(id: number) {
    try {
      return await api.put(`/payments/${id}/update-status`, undefined, {
        params: { syncBills: true, syncSubscriptions: true },
      });
    } catch (err: any) {
      return err.response;
    }
  }

  static async exportStudentFinancialReport(id: number) {
    try {
      return await api.get(`/payments/${id}/student-financial-report`);
    } catch (err: any) {
      return err.response;
    }
  }

  static async exportIncomeTaxReturnByPaymentId(paymentId: number) {
    try {
      return await api.post(`/payments/${paymentId}/income-tax-return`);
    } catch (err: any) {
      return err.response;
    }
  }

  static async canExportIRByPaymentId(paymentId: number) {
    try {
      return await api.post(`/payments/${paymentId}/can-export-ir`);
    } catch (err: any) {
      return err.response;
    }
  }

  static async getCanExportIRByPaymentId(paymentId: number) {
    try {
      return await api.get(`/payments/${paymentId}/can-export-ir`);
    } catch (err: any) {
      return err.response;
    }
  }

  static async exportIncomeTaxReturnByCanceledPaymentId(paymentId: number) {
    try {
      return await api.post(
        `/payments/${paymentId}/income-tax-return-by-canceled-payment`,
      );
    } catch (err: any) {
      return err.response;
    }
  }

  static async handleCreatePaymentMarketplace(payload: {
    registrationId: number;
    subscriptions: SubscriptionsService[];
  }) {
    try {
      return await api.post(`/payments/marketplace`, payload);
    } catch (err: any) {
      return err.response;
    }
  }

  static async deleteBill(id: number) {
    try {
      return await api.delete(`/bills/${id}`);
    } catch (err: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(err));
    }
  }

  static async getPaymentForInvoiceByPaymentId(paymentId: number) {
    try {
      return await api.get(`/payments/${paymentId}/get-data-for-invoice`);
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async getLastPaymentFromRegistrationId(
    registrationId: number | string,
  ) {
    try {
      return await api.get<Payment>(
        `/payments/last-payment-from-registration-id`,
        {
          params: { registrationId },
        },
      );
    } catch (error: any) {
      throw new Error(getAxiosResponseMessageErrorOrDefault(error));
    }
  }

  static async getDefaultingPayment(financialGuardianId: number) {
    try {
      return await api.get(`/payments/get-defaulting-payment`, {
        params: { financialGuardianId },
      });
    } catch (error: any) {
      return error.response;
    }
  }
}
