export const MISSING_CARD =
  'Seu cartão não está nessa lista? Entre em contato com a escola e solicite a inclusão.';
export const CARD_DELETE_SUCCESS = 'Cartão removido com sucesso.';
export const CARD_BLOCK_SUCCESS = 'Cartão bloqueado com sucesso.';
export const CARD_UNBLOCK_SUCCESS = 'Cartão desbloqueado com sucesso.';
export const CARD_ADDED = 'Cartão cadastrado com sucesso.';
export const PAYMENT_CANCELED = 'Pagamento cancelado com sucesso.';
export const PAYMENT_REFUNDED = 'Pagamento estornado com sucesso.';
export const BILL_CANCELED = 'Fatura cancelada com sucesso.';
export const BILL_REFUNDED = 'Fatura estornada com sucesso.';
export const VINDI_SYNCED =
  'A sincronização com a vindi está sendo processada. Em alguns minutos será concluída, recarregue a página para visualizar.';
export const INCOME_TAX_EXPORT_FORBIDDEN =
  'A sua declaração de IR não está disponível. Entre em contato com a escola para mais informações.';
export const INCOME_TAX_EXPORT_EXPORT =
  'Permissão de exportação de IR alterada com sucesso.';
